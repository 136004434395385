
export default {
  name: 'DSButton',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: value => ['brand', 'primary', 'secondary', 'tertiary', 'ghost', 'link-white'].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].includes(value),
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
      validator: value => ['', '_blank', '_self', '_parent', '_top'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return `ds-button--${this.variant} ds-button--${this.size}`
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event)
    },
  },
}
