
import { mapState } from 'vuex'

const GvButton = () => import('~/components/Button/GvButton.vue')
const DSButton = () => import('~/components/DS/Button/Button.vue')

export default {
  components: {
    GvButton, DSButton,
  },
  data() {
    return {
      currentIndex: 0,
    }
  },
  computed: {
    ...mapState({
      locale: state => state.settings.locale,
    }),
    fuel_your_creativity() {
      return this.$t('landing_page.fuel_your_creativity')
    },
    reward_your_fans() {
      return this.$t('landing_page.reward_your_fans')
    },
    sell_exclusive_content() {
      return this.$t('landing_page.sell_exclusive_content')
    },
    get_commissions() {
      return this.$t('landing_page.get_commissions')
    },
    chat_with_your_community() {
      return this.$t('landing_page.chat_with_your_community')
    },
    earn_more_with_campaigns() {
      return this.$t('landing_page.earn_more_with_campaigns')
    },
    collab_with_creators() {
      return this.$t('landing_page.collab_with_creators')
    },
    get_paid_with_ease() {
      return this.$t('landing_page.get_paid_with_ease')
    },
    textList() {
      return [
        this.fuel_your_creativity,
        this.reward_your_fans,
        this.sell_exclusive_content,
        this.get_commissions,
        this.chat_with_your_community,
        this.earn_more_with_campaigns,
        this.collab_with_creators,
        this.get_paid_with_ease,
      ]
    },
  },
  mounted() {
    this.animateText()
  },
  methods: {
    animateText() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.textList.length
      }, 3000)
    },
    handleSignUp() {
      this.$gtm.push({
        event: 'signup_initiation_landing_cta',
      })
      localStorage.setItem('trackSignupFromHomepage', true)
      localStorage.removeItem('loginRedirect')
      this.$router.push('/signup')
    },
  },
}
